import * as React from "react"
import Base from "../components/templates/Base"

import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Base>
      <section className="bg-colour--tan t-bump hero-404">
        <div className="inner-div">
          <img src={'/404.svg'} alt="Page Not Found" />
          <h1>Sorry, that page can’t be found.</h1>
          <div className="text">Would you like to check out our <Link to='/case-studies'>case studies</Link>, our <Link to='/features'>features</Link> or <Link to='/'>head back home</Link>?</div>
        </div>
      </section>
    </Base>
  );
};

export default NotFoundPage;